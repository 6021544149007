<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.Id"
        placeholder="ID"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.Email"
        placeholder="Email"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.FirstName"
        placeholder="FirstName"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.ReferralCode"
        placeholder="ReferralCode"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.UplineEmail"
        placeholder="UplineEmail"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-date-picker
          class="filter-item"
          v-model="dateArr"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          value-format="yyyy-MM-dd HH:mm:ss"
          unlink-panels
          range-separator="-"
          start-placeholder="start"
          end-placeholder="end"
          size="small"
      ></el-date-picker>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >Search</el-button
      >
      <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="handleExport"
          type="xls"
          name="Users.xls"
        >
        <el-button
          type="primary"
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleFilter"
        >Export</el-button>
        </download-excel>
      </span>
      
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <!-- <el-table-column align="center" label="ID" width="95">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column> -->
      <el-table-column label="Email" min-width="180" align="center">
        <template slot-scope="scope">
          {{ scope.row.Email }}
        </template>
      </el-table-column>
      <el-table-column label="Name" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.FirstName }}
        </template>
      </el-table-column>
      <el-table-column label="ReferralCode" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.ReferralCode }}
        </template>
      </el-table-column>
      <el-table-column label="TotalReferral" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.TotalReferral }}
        </template>
      </el-table-column>
      <el-table-column label="Active" min-width="40" align="center">
        <template slot-scope="scope">
          {{ scope.row.Active }}
        </template>
      </el-table-column>
      <el-table-column label="Created on" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.CreatedOnUtc | dateStr }}
        </template>
      </el-table-column>
      <el-table-column label="Last activity" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.LastActivityDateUtc | dateStr }}
        </template>
      </el-table-column>
      <el-table-column
        label="Options"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleEdit(scope.row)"
            >Edit</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog title="Edit" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="80%">
      <el-form ref="form" :model="form" size="small" label-position="left" label-width="180px" style="width: 90%; margin-left:20px;">
        <el-form-item label="Id" prop="Id">
          <el-input disabled v-model="form.Id"/>
        </el-form-item>
        <el-form-item label="Email" prop="Email">
          <el-input v-model="form.Email"/>
        </el-form-item>
        <el-form-item label="FirstName" prop="FirstName">
          <el-input type="text" v-model="form.FirstName"/>
        </el-form-item>
        <el-form-item label="ReferralCode" prop="ReferralCode">
          <el-input disabled type="text" v-model="form.ReferralCode"/>
        </el-form-item>
        <el-form-item label="Upline Email" prop="UplineEmail">
          <el-input disabled type="text" v-model="form.UplineEmail"/>
        </el-form-item>
        <el-form-item label="SubAccLoginId" prop="SubAccLoginId">
          <el-input type="text" v-model="form.SubAccLoginId"/>
        </el-form-item>
        <el-form-item label="SubAccUserId" prop="SubAccUserId">
          <el-input type="text" v-model="form.SubAccUserId"/>
        </el-form-item>
        <el-form-item label="IsAmbassador" prop="IsAmbassador">
          <el-switch v-model="form.IsAmbassador" />
        </el-form-item>
        <el-form-item label="IsBD" prop="IsBD">
          <el-switch v-model="form.IsBD" />
        </el-form-item>
        <el-form-item label="IsAdmin" prop="IsAdmin">
          <el-switch v-model="form.IsAdmin" />
        </el-form-item>
        <el-form-item label="CreatedOnUtc" prop="CreatedOnUtc">
          {{ form.CreatedOnUtc }}
        </el-form-item>
        <el-form-item label="LastActivityDateUtc" prop="LastActivityDateUtc">
          {{ form.LastActivityDateUtc }}
        </el-form-item>
        <el-form-item label="LastIpAddress" prop="LastIpAddress">
          {{ form.LastIpAddress }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">Save</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        Id: '',
        Email: '',
        FirstName: "",
        ReferralCode: "",
        UplineEmail: "",
        StartTime: "",
        EndTime: "",
      },
      dialogFormVisible: false,
      btnLoading: false,
      dateArr: null,
      form: {
      },
      exportLoading: false,
      json_fields: {
        Id: 'Id',
        Email: 'Email',
        FirstName: 'FirstName',
        ReferralCode: 'ReferralCode',
        UplineEmail: 'UplineEmail',
        SubAccLoginId: 'SubAccLoginId',
        SubAccUserId: 'SubAccUserId',
        IsAmbassador: 'IsAmbassador',
        IsBD: 'IsBD',
        IsAdmin: 'IsAdmin',
        CreatedOnUtc: 'CreatedOnUtc',
        LastActivityDateUtc: 'LastActivityDateUtc',
        LastIpAddress: 'LastIpAddress',
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.StartTime = newVal[0];
        this.listQuery.EndTime = newVal[1];
      } else {
        this.listQuery.StartTime = "";
        this.listQuery.EndTime = "";
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/admin/customer/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        console.log(response.data);
        this.list = response.data.Data;
        this.total = response.data.Total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(customer) {
      this.form = {
        USDT_Address: customer.USDT_Address,
        USDT_Chain: customer.USDT_Chain,
        POT_Address: customer.POT_Address,
        AMBASSADOR_Address: customer.AMBASSADOR_Address,
        Id: customer.Id,
        Email: customer.Email,
        IsModerator: customer.IsModerator,
        IsAmbassador: customer.IsAmbassador,
        IsBD: customer.IsBD,
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        ReferralCode: customer.ReferralCode,
        TotalReferral: customer.TotalReferral,
        Active: customer.TraderActive,
        CreatedOnUtc: customer.CreatedOnUtc,
        LastActivityDateUtc: customer.LastActivityDateUtc,
        LastIpAddress: customer.LastIpAddress,
        SubAccUserId: customer.SubAccUserId,
        SubAccLoginId: customer.SubAccLoginId,
        UplineEmail: customer.UplineEmail,
        IsAdmin: customer.IsAdmin,
      };
      this.dialogFormVisible = true;
    },
    saveData() {
      this.btnLoading = true;
      request({
        url: "/api/admin/customer/update",
        method: "post",
        data: {
          Id: this.form.Id,
          Email: this.form.Email,
          FirstName: this.form.FirstName,
          SubAccLoginId: this.form.SubAccLoginId,
          SubAccUserId: this.form.SubAccUserId,
          IsAmbassador: this.form.IsAmbassador,
          IsBD: this.form.IsBD,
          IsAdmin: this.form.IsAdmin,
        }
      }).then(response => {
        this.dialogFormVisible = false;
        this.$notify({
          title: "成功",
          message: "操作成功",
          type: "success",
          duration: 2000
        });
        this.getList();
      }).finally(() => {
        this.btnLoading = false;
      });
    },
    handleExport() {
      this.exportLoading = true;
      var query = JSON.parse(JSON.stringify(this.listQuery));
      query.limit = 99999999999999;
      return request({
        url: "/api/admin/customer/list",
        method: "get",
        params: query,
      })
      .then((result) => {
        var json_data = result.data.Data;
        return json_data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.exportLoading = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
